import React from 'react'
import Helmet from 'react-helmet'

const SEO = ({title, description, logo, favicon}) =>
  <Helmet>

    <title>{title}</title>
    <link rel='shortcut icon' href={favicon} />

    <meta property='description' content={description} />

    <meta property='og:title' content={title} />
    <meta property='og:type' content='website' />
    <meta property='og:description' content={description} />
    <meta property='og:image' content={logo} />

    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
    <meta name='twitter:image:src' content={logo} />

    <meta itemProp='name' content={title} />
    <meta itemProp='description' content={description} />
    <meta itemProp='image' content={logo} />

  </Helmet>

export default SEO