import React from 'react'
import styled, { injectGlobal } from 'styled-components'
import normalize from 'styled-normalize'
import get from 'lodash/get'

import Footer from './Footer'
import SEO from './SEO'

import logo from '../assets/img/logo.png'
import favicon from '../assets/img/favicon.ico'
import colors from '../assets/js/colors'

injectGlobal`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans"); // keep here to styled footer and non-template pages
  
  ${normalize}
  
  html {
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    box-sizing: border-box;
	  white-space: pre-wrap; // allows line-breaks in frontmatter
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  padding-bottom: 50px; // for footer
  min-height: 100vh;
  width: 100%;
`

const Layout = ({children, frontmatter, ...others}) =>
  <Wrapper backgroundColor={get(frontmatter, 'backgroundColor', colors.white)} {...others}>
    <SEO
      title={get(frontmatter, 'title')}
      description={get(frontmatter, 'description')}
      logo={get(frontmatter, 'logo', logo)}
      favicon={get(frontmatter, 'favicon', favicon)}
    />
    {children}
    <Footer />
  </Wrapper>

export default Layout